import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-9bb64424"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "trajectory_wrapper"
};
const _hoisted_2 = {
  key: 0,
  class: "FenceTable"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_TrajectoryMap = _resolveComponent("TrajectoryMap");
  const _component_PosTable = _resolveComponent("PosTable");
  const _component_FenceTable = _resolveComponent("FenceTable");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    class: _normalizeClass(['trajectory', _ctx.showFence && 'main_min'])
  }, [_createVNode(_component_TrajectoryMap, {
    ref: "refMap",
    showFence: _ctx.showFence,
    "onUpdate:showFence": _cache[0] || (_cache[0] = $event => _ctx.showFence = $event),
    class: _normalizeClass(['map_box']),
    loading: _ctx.fetchLoading,
    onSearch: _ctx.onSearch
  }, null, 8, ["showFence", "loading", "onSearch"]), _createVNode(_component_PosTable, {
    class: "table_box",
    loading: _ctx.fetchLoading
  }, null, 8, ["loading"])], 2), _ctx.showFence ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_FenceTable, {
    isEdit: false,
    onRowClick: _ctx.fenceClick
  }, null, 8, ["onRowClick"])])) : _createCommentVNode("", true)]);
}